import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination, Thumbs } from "swiper";

function Gallery(props) {
  return (
    <Swiper
      style={{
        "--swiper-navigation-color": "#fff",
        "--swiper-pagination-color": "#fff",
        "--swiper-pagination-bullet-inactive-color": "#fff",
        color: "white",
      }}
      modules={[Navigation, Pagination, Thumbs]}
      spaceBetween={10}
      navigation={true}
      pagination={{ type: "fraction" }}
      className="bg-black"
    >
      {props.data.map((d, i) => (
        <SwiperSlide key={i} className="flex items-center justify-center">
          <img src={d} alt="gallery" />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

export default Gallery;
