import { Link } from "react-router-dom";

function Books(props) {
  return (
    <div className="mt-4 grid grid-cols-2 gap-4">
      {props.data.map((d, i) => (
        <Link key={i} to={d.link}>
          <div className="flex w-full flex-col items-center">
            <img src={d.image} alt="book" className="h-64 border-2" />
            <div className="mt-3 text-center">
              {d.categories &&
                d.categories.map((c, ci) => (
                  <span
                    key={ci}
                    className="mr-1 rounded-full border border-gray-300 px-3 py-1 text-xs"
                  >
                    {c}
                  </span>
                ))}
              <div className="mt-2 font-semibold">{d.title}</div>
              <div className="mt-1 break-keep text-sm">{d.excerpt}</div>
              <div className="mt-1 text-sm text-gray-500">
                {d.writer} | {d.publisher}
              </div>
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
}

export default Books;
