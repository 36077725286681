import { Link } from "react-router-dom";

function Photos(props) {
  return (
    <div className="grid grid-cols-2 gap-4">
      {props.data.map((d, i) => (
        <Link key={i} to={d.link}>
          <div
            style={{
              backgroundImage: `url(${d.image})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
            className="aspect-square w-full object-cover"
          >
            <div className="h-full w-full bg-gradient-to-t from-black/50 to-transparent">
              <div className="flex h-full w-full flex-col justify-end p-4  text-white">
                {d.categories &&
                  d.categories.map((c, ci) => (
                    <span
                      key={ci}
                      className="w-fit rounded-full bg-white px-3 py-1 text-sm text-black"
                    >
                      {c}
                    </span>
                  ))}
                <div className="mt-2 font-bold">{d.title}</div>
                <div className="mt-1 text-xs">{d.date}</div>
              </div>
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
}

export default Photos;
