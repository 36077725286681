import { Link } from "react-router-dom";

function Cards(props) {
  return (
    <div className="-mb-4">
      {props.data.map((d, i) => (
        <Link key={i} to={d.link}>
          <div className="mb-4 flex h-96 w-full flex-col rounded-xl border-2">
            <img
              src={d.image}
              alt="book"
              className="h-60 w-full rounded-t-xl object-cover"
            />
            <div className="p-4 pb-0">
              <div className="mt-1 font-semibold">
                <span>{d.title}</span>
              </div>
              <div className="mt-1 text-sm">{d.excerpt}</div>
            </div>
            <div className="mt-auto p-4 text-xs text-gray-500">{d.date}</div>
          </div>
        </Link>
      ))}
    </div>
  );
}

export default Cards;
