import { Tab } from "@headlessui/react";
import { useState, useCallback, useEffect } from "react";
import Books from "../components/lists/Books";
import Photos from "../components/lists/Photos";
import Cards from "../components/lists/Cards";
import {
  getThumbnailImage,
  removeHtmlTags,
  dateFormat,
  getCategories,
  getWriter,
  getPublisher,
} from "../scripts/Utilities";
import { useLocation, useNavigate } from "react-router-dom";

function Contents() {
  // eslint-disable-next-line no-unused-vars
  const [title, setTitle] = useState("콘텐츠");
  useEffect(() => {
    document.title = `${title} | 부천YWCA`;
  }, [title]);

  const location = useLocation();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(0);
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const tabs = [
    {
      id: "notice",
      name: "공지사항",
      panel: <Cards data={results} />,
      target: `${process.env.REACT_APP_API_URL}/posts?categories=3`,
    },
    {
      id: "news",
      name: "소식",
      panel: <Cards data={results} />,
      target: `${process.env.REACT_APP_API_URL}/posts?categories=1,4`,
    },
    {
      id: "library",
      name: "도서관",
      panel: <Books data={results} />,
      target: `${process.env.REACT_APP_LIB_URL}/posts?`,
    },
    {
      id: "gallery",
      name: "갤러리",
      panel: <Photos data={results} />,
      target: `${process.env.REACT_APP_API_URL}/posts?categories=4`,
    },
  ];

  useEffect(() => {
    const hashTabIndex = tabs.findIndex((t) => t.id === location.hash.slice(1));
    const selectedIndex = hashTabIndex > 0 ? hashTabIndex : 0;
    getContents(selectedIndex);
    setSelectedTab(selectedIndex);
    // return () => {
    //   setSelectedTab(selectedIndex);
    // };
  }, [selectedTab]);

  const onTabChange = useCallback(
    (index) => {
      navigate(`#${tabs[index].id}`);
      setSelectedTab(index);
      setResults([]);
    },
    [selectedTab]
  );

  const getContents = useCallback(
    (index) => {
      setLoading(true);
      const page = Math.ceil(results.length / 6) + 1;
      fetch(
        `${tabs[index].target}&_fields=id,title,content,excerpt,date,_links&_embed=wp:term&page=${page}&per_page=6`
      )
        .then((r) => r.json())
        .then((data) => {
          setLoading(false);
          if (data && data.length > 0) {
            const propData =
              index === 2 // library
                ? data.map((d) => ({
                    link: `/books/${d.id}`,
                    image: getThumbnailImage(d.content.rendered),
                    title: removeHtmlTags(d.title.rendered),
                    content: removeHtmlTags(d.content.rendered),
                    excerpt: removeHtmlTags(d.excerpt.rendered),
                    categories: getCategories(d),
                    writer: getWriter(d),
                    publisher: getPublisher(d),
                  }))
                : data.map((d) => ({
                    link: `/${index === 3 ? "photos" : "posts"}/${d.id}`,
                    image: getThumbnailImage(d.content.rendered),
                    title: removeHtmlTags(d.title.rendered),
                    content: removeHtmlTags(d.content.rendered),
                    excerpt: removeHtmlTags(d.excerpt.rendered),
                    date: dateFormat(d.date),
                  }));
            if (page === 1) setResults(propData);
            else setResults(results.concat(propData));
          }
        });
    },
    [results]
  );

  return (
    <div>
      <Tab.Group selectedIndex={selectedTab} onChange={onTabChange}>
        <Tab.List className="flex h-16 w-full border-b border-gray-300">
          {tabs.map((t, i) => (
            <Tab
              key={i}
              className={`grow focus:outline-none focus:ring-0 ui-selected:border-b-2 ui-selected:border-blue-500 ui-selected:text-blue-500`}
            >
              {t.name}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels>
          {tabs.map((t, i) => (
            <Tab.Panel key={i} className="p-4">
              {t.panel}
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
      <div className="mt-4 mb-8">
        {results.length > 0 && (
          <div className="flex justify-center">
            <button
              className="rounded-lg border border-blue-500 py-1 px-3 text-center font-bold text-blue-500 disabled:border-gray-400 disabled:text-gray-400"
              onClick={() => getContents(selectedTab)}
              disabled={loading}
            >
              더보기
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Contents;
