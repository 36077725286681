import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Link } from "react-router-dom";

function Banners(props) {
  return (
    <Swiper
      modules={[Pagination, Autoplay]}
      grabCursor={true}
      centeredSlides={true}
      slidesPerView={1.5}
      initialSlide={1}
      spaceBetween={12}
      loop={true}
      autoplay={{
        delay: 4000,
        disableOnInteraction: true,
      }}
    >
      {props.data.map((d, i) => {
        return (
          <SwiperSlide key={i}>
            <Link to={d.link}>
              <div
                className="flex h-32 w-full items-center rounded-xl p-6"
                style={{ backgroundColor: d.color }}
              >
                <div className="mt-1 w-full font-semibold">
                  <span>{d.title}</span>
                </div>
                <img
                  src={d.image}
                  alt="book"
                  className="h-16 w-full object-contain"
                />
              </div>
            </Link>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}

export default Banners;
