import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Link } from "react-router-dom";

function Cards(props) {
  return (
    <Swiper
      modules={[Pagination]}
      grabCursor={true}
      centeredSlides={true}
      slidesPerView={1.3}
      initialSlide={1}
      spaceBetween={4}
      loop={true}
    >
      {props.data.map((d, i) => {
        return (
          <SwiperSlide key={i}>
            <Link to={d.link}>
              <div className="flex h-96 w-full flex-col rounded-xl border-2">
                <img
                  src={d.image}
                  alt="book"
                  className="h-48 w-full rounded-t-xl object-cover"
                />
                <div className="p-4 pb-0">
                  {d.categories.map((c, ci) => (
                    <span key={ci} className="mr-1 text-sm">
                      {c}
                    </span>
                  ))}
                  <div className="mt-1 font-semibold">
                    <span>{d.title}</span>
                  </div>
                  <div className="mt-1 text-sm">{d.excerpt}</div>
                </div>
                <div className="mt-auto p-4 text-xs text-gray-500">
                  {d.date}
                </div>
              </div>
            </Link>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}

export default Cards;
