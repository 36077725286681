import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  removeHtmlTags,
  dateFormat,
  getThumbnailImage,
} from "../../scripts/Utilities";
import "./index.css";

function Article(props) {
  // eslint-disable-next-line no-unused-vars
  const [title, setTitle] = useState("페이지");
  useEffect(() => {
    document.title = `${title} | 부천YWCA`;
  }, [title]);

  const { id } = useParams();
  let [article, setArticle] = useState({});

  useEffect(() => {
    fetch(
      `${
        props.src === "main"
          ? process.env.REACT_APP_API_URL
          : process.env.REACT_APP_LIB_URL
      }/${props.type}/${id}?_fields=title,content,date`
    )
      .then((r) => r.json())
      .then((data) => {
        if (data) {
          setTitle(removeHtmlTags(data.title.rendered));
          setArticle({
            image: getThumbnailImage(data.content.rendered),
            title: removeHtmlTags(data.title.rendered),
            content: data.content.rendered,
            date: dateFormat(data.date),
          });
        }
      });

    return () => {
      article = {};
    };
  }, []);

  return (
    <div>
      <div
        dangerouslySetInnerHTML={{ __html: article.content }}
        className="article-content mb-8 text-lg"
      ></div>
    </div>
  );
}

export default Article;
