import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function NoMatch() {
  // eslint-disable-next-line no-unused-vars
  const [title, setTitle] = useState("404");
  useEffect(() => {
    document.title = `${title} | 부천YWCA`;
  }, [title]);

  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center">
      <div>페이지를 찾을 수 없습니다.</div>
      <button className="underline" onClick={() => navigate(-1)}>
        돌아가기
      </button>
    </div>
  );
}

export default NoMatch;
