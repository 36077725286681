import { Link, useLocation } from "react-router-dom";
import {
  HomeIcon,
  UserIcon,
  ArchiveBoxIcon,
  IdentificationIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";

function Navigation() {
  const location = useLocation();
  const navigations = [
    { link: "/", title: "홈", icon: <HomeIcon /> },
    { link: "/pages/2", title: "소개", icon: <UserIcon /> },
    { link: "/search", title: "검색", icon: <MagnifyingGlassIcon /> },
    { link: "/contents", title: "콘텐츠", icon: <ArchiveBoxIcon /> },
    { link: "/pages/1228", title: "회원가입", icon: <IdentificationIcon /> },
  ];
  return (
    <div className="bg-gray navigation fixed bottom-0 z-10 w-full max-w-lg border-t-2 bg-white py-2">
      <div className="grid grid-cols-5">
        {navigations.map((n, i) => {
          return (
            <Link
              to={n.link}
              key={i}
              className={location.pathname === n.link ? "text-blue-600" : ""}
            >
              <div className="mx-auto h-6 w-6">{n.icon}</div>
              <div className="mt-1 text-center text-xs">{n.title}</div>
            </Link>
          );
        })}
      </div>
    </div>
  );
}

export default Navigation;
