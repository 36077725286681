import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css";
import { Link } from "react-router-dom";

function Vertical(props) {
  return (
    <Swiper
      modules={[Autoplay]}
      direction={"vertical"}
      loop={true}
      autoplay={{
        delay: 4000,
        disableOnInteraction: true,
      }}
      className="h-12"
    >
      {props.data.map((d, i) => {
        return (
          <SwiperSlide key={i}>
            <Link to={d.link}>
              <div className="p-3" style={{ height: "auto !important" }}>
                {d.categories.map((c, ci) => (
                  <span
                    key={ci}
                    className="mr-1 rounded-full bg-black px-3 py-1 text-xs text-white"
                  >
                    {c}
                  </span>
                ))}
                <div className="mt-3 inline text-sm">{d.title}</div>
              </div>
            </Link>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}

export default Vertical;
