import qrcode from "../assets/qrcode.png";

function Layout({ children }) {
  const formUrl = "https://forms.gle/kyftZU6Gze4RBmMN8";

  return (
    <div className="flex justify-center">
      <div className="hidden sm:flex sm:h-screen sm:w-full sm:max-w-lg sm:items-center">
        <div className="w-full text-4xl font-black">
          <div>생명의 바람</div>
          <div>세상을 살리는 여성</div>
          <div className="text-blue-800">(사)부천YWCA</div>
          <div className="-ml-7 flex items-center p-3">
            <img src={qrcode} alt="qrcode" className="inline h-32 w-32 p-6" />
            <div className="inline text-lg font-semibold">
              <div>여성과 함께, 변화를 향해.</div>
              <div className="text-base font-normal">
                <div>변화를 향해 나가는</div>
                <div>여정에 함께 동참해 주세요.</div>
                <a
                  href={formUrl}
                  target="_blank"
                  className="text-blue-600"
                  rel="noreferrer"
                >
                  (사)부천YWCA 회원가입하기
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="h-screen w-full max-w-lg overflow-x-hidden overflow-y-scroll shadow-2xl"
        style={{ minHeight: "-webkit-fill-available" }}
      >
        {children}
      </div>
    </div>
  );
}

export default Layout;
