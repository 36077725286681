import Gallery from "../components/slides/Gallery";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { removeHtmlTags, getEveryImages, getOnlyTexts } from "../scripts/Utilities";

function Photo() {
  // eslint-disable-next-line no-unused-vars
  const [title, setTitle] = useState("갤러리");
  useEffect(() => {
    document.title = `${title} | 부천YWCA`;
  }, [title]);

  const { id } = useParams();
  const [gallery, setGallery] = useState([]);
  const [content, setContent] = useState();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/posts/${id}?_fields=title,content`)
      .then((r) => r.json())
      .then((data) => {
        if (data) {
          if (data) {
            setTitle(removeHtmlTags(data.title.rendered));
            setGallery(getEveryImages(data.content.rendered));
            setContent(getOnlyTexts(data.content.rendered));
          }
        }
      });
  }, []);

  return (
    <div>
      <div
        className="flex max-w-lg w-full"
        style={{
          height: "32rem",
        }}
      >
        <Gallery data={gallery} />
      </div>
      <div
          dangerouslySetInnerHTML={{ __html: content }}
          className="article-content px-5 my-5 text-lg"
        ></div>
    </div>
  );
}

export default Photo;
