import { Tab } from "@headlessui/react";
import { useState, useCallback, useEffect } from "react";
import List from "../components/lists/List";
import { removeHtmlTags } from "../scripts/Utilities";

function Search() {
  // eslint-disable-next-line no-unused-vars
  const [title, setTitle] = useState("검색");
  useEffect(() => {
    document.title = `${title} | 부천YWCA`;
  }, [title]);

  const [selectedTab, setSelectedTab] = useState(0);
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const recommand = ["여성", "인문", "환경", "기후위기", "피스오브피스"];
  const tabs = [
    { name: "YWCA", panel: <List data={results} /> },
    { name: "도서관", panel: <List data={results} /> },
  ];

  const onTabChange = useCallback(
    (index) => {
      setSelectedTab(index);
      setResults([]);
      setLoading(false);
    },
    [selectedTab]
  );

  const search = useCallback(() => {
    setLoading(true);
    const text = document.getElementById("search").value;
    fetch(
      `${
        selectedTab === 0
          ? process.env.REACT_APP_API_URL
          : process.env.REACT_APP_LIB_URL
      }/search?search=${text}&page=${
        Math.ceil(results.length / 5) + 1
      }&per_page=5&_fields=_links&_embed`
    )
      .then((r) => r.json())
      .then((data) => {
        if (data && data.length > 0) {
          setResults(
            results.concat(
              data.map((d) => ({
                title: removeHtmlTags(d._embedded.self[0].title.rendered),
                content: removeHtmlTags(d._embedded.self[0].excerpt.rendered),
                link: `/${
                  selectedTab === 1 ? "book" : d._embedded.self[0].type
                }s/${d._embedded.self[0].id}`,
              }))
            )
          );
          setLoading(false);
        }
      });
  }, [results]);

  return (
    <div>
      <Tab.Group selectedIndex={selectedTab} onChange={onTabChange}>
        <Tab.List className="flex h-16 w-full border-b border-gray-300">
          {tabs.map((t, i) => (
            <Tab
              key={i}
              className={`grow focus:outline-none focus:ring-0 ui-selected:border-b-2 ui-selected:border-blue-500 ui-selected:text-blue-500`}
            >
              {t.name}
            </Tab>
          ))}
        </Tab.List>
        <div className="border-b p-4">
          <div className="flex">
            <input
              type="text"
              name="search"
              id="search"
              className="mr-2 grow rounded-lg border py-1 px-3"
              placeholder="검색어를 입력하세요"
            />
            <button
              className="grow-0 rounded-lg bg-blue-400 py-1 px-3 font-bold text-white disabled:bg-gray-300"
              disabled={loading}
              onClick={() => {
                results.length = 0;
                search();
              }}
            >
              검색
            </button>
          </div>
          <div className="mt-4">
            <div className="text-xs">추천검색어</div>
            <div className="mt-1">
              {recommand.map((k, i) => {
                return (
                  <span
                    key={i}
                    className="mr-1 rounded-full border border-gray-300 px-3 py-1 text-xs"
                    onClick={() => {
                      results.length = 0;
                      document.getElementById("search").value = k;
                      search();
                    }}
                  >
                    {k}
                  </span>
                );
              })}
            </div>
          </div>
        </div>
        <Tab.Panels className="px-4">
          {tabs.map((t, i) => (
            <Tab.Panel key={i}>{t.panel}</Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
      <div>
        {results.length > 0 && (
          <div className="my-8 flex justify-center">
            <button
              className="rounded-lg border border-blue-500 py-1 px-3 font-bold text-blue-500 disabled:border-gray-400 disabled:text-gray-400"
              disabled={loading}
              onClick={() => search()}
            >
              더보기
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Search;
