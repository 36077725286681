import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Link } from "react-router-dom";

function Books(props) {
  return (
    <Swiper
      modules={[Pagination, Autoplay]}
      grabCursor={true}
      centeredSlides={true}
      slidesPerView={2}
      initialSlide={1}
      spaceBetween={4}
      loop={true}
      autoplay={{
        delay: 6000,
        disableOnInteraction: true,
      }}
    >
      {props.data.map((d, i) => {
        return (
          <SwiperSlide key={i}>
            <Link to={d.link}>
              <div className="flex w-full flex-col items-center">
                <img src={d.image} alt="book" className="h-64 border-2" />
                <div className="mt-3 text-center">
                  {d.categories.map((c, ci) => (
                    <span
                      key={ci}
                      className="mr-1 rounded-full border border-gray-300 px-3 py-1 text-xs"
                    >
                      {c}
                    </span>
                  ))}
                  <div className="mt-2 font-semibold">{d.title}</div>
                  <div className="mt-1 break-keep text-sm">{d.excerpt}</div>
                  <div className="mt-1 text-sm text-gray-500">
                    {d.writer} | {d.publisher}
                  </div>
                </div>
              </div>
            </Link>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}

export default Books;
