import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

function List(props) {
  return (
    <div>
      <div>
        {props.data.map((d, i) => (
          <Link key={i} className="flex h-20 w-full border-b" to={d.link}>
            <div className="h-full w-5/6 p-4">
              <div className="whitespace-nowrap">{d.title}</div>
              <div className="overflow-hidden text-ellipsis whitespace-nowrap text-sm">
                {d.content}
              </div>
            </div>
            <div className="flex h-full w-1/6 items-center justify-center">
              <ChevronRightIcon className="h-4" />
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default List;
