import world from "../assets/logos/world-ywca.png";
import korea from "../assets/logos/korea-ywca.png";
import gyeonggido from "../assets/logos/gyeonggido.jpg";

function Footer() {
  return (
    <div className="mb-16">
      <div className="border-y-2 p-4">
        <div>(사)부천YWCA</div>
        <div className="mt-1 text-sm">
          <div>
            <span className="mr-2 text-gray-500">사업자등록번호</span>
            <span className="text-gray-700">426-82-00630</span>
          </div>
          <div>
            <span className="mr-2 text-gray-500">주소</span>
            <span className="text-gray-700">
              부천시 원미로 156번길 38 지평빌딩 나동 2층
            </span>
          </div>
          <div>
            <span className="mr-2 text-gray-500">전화번호</span>
            <span className="text-gray-700">032-668-9700~9701</span>
          </div>
          <div>
            <span className="mr-2 text-gray-500">이메일</span>
            <span className="text-gray-700">pcywca@hanmail.net</span>
          </div>
        </div>
      </div>
      <div className="border-b-2 p-4 grid grid-cols-4 gap-4">
        <a href="http://www.worldywca.org/" className="inline-block flex items-center">
          <img src={world} alt="logo"/>
        </a>
        <a href="https://ywca.or.kr/" className="inline-block flex items-center">
          <img src={korea} alt="logo"/>
        </a>
        <a href="https://www.gg.go.kr" className="inline-block flex items-center">
          <img src={gyeonggido} alt="logo"/>
        </a>
        <a href="https://www.nts.go.kr/" className="inline-block flex items-center">
          <img src="https://www.nts.go.kr/images/web/nts/layout/logo1.png" alt="logo"/>
        </a>
      </div>
      <div className="align-center flex justify-center p-8">
        <div className="text-xs font-thin">
          ⓒ {new Date().getFullYear()} YWCA Bucheon | icons by{" "}
          <a href="https://icons8.com">icons8</a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
