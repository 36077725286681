import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Link } from "react-router-dom";

function Carousel(props) {
  return (
    <Swiper
      style={{
        "--swiper-navigation-color": "#fff",
        "--swiper-pagination-color": "#fff",
        "--swiper-pagination-bullet-inactive-color": "#fff",
      }}
      modules={[Pagination, Autoplay]}
      pagination={true}
      autoplay={{
        delay: 6000,
        disableOnInteraction: true,
      }}
      loop={true}
      initialSlide={1}
    >
      {props.data.map((d, i) => {
        return (
          <SwiperSlide key={i}>
            <Link to={d.link}>
              <div
                style={{
                  backgroundImage: `url(${d.image})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
                className="h-80"
              >
                <div className="h-full w-full bg-gradient-to-t from-black/50 to-transparent">
                  <div className="flex h-full w-full flex-col justify-end p-6 pb-8  text-white">
                    {d.categories.map((c, ci) => (
                      <span
                        key={ci}
                        className="w-fit rounded-full bg-white px-3 py-1 text-sm text-black"
                      >
                        {c}
                      </span>
                    ))}
                    <div className="mt-2 text-xl font-bold">{d.title}</div>
                    <div className="mt-1">{d.excerpt}</div>
                  </div>
                </div>
              </div>
            </Link>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}

export default Carousel;
