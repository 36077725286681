function getThumbnailImage(html) {
  const parser = new DOMParser();
  const content = parser.parseFromString(html, "text/html");
  const image = content.querySelector("img");
  return image ? image.src : null;
}

function getEveryImages(html) {
  const parser = new DOMParser();
  const content = parser.parseFromString(html, "text/html");
  const images = content.querySelectorAll("img");
  return images ? Array.prototype.slice.call(images).map((i) => i.src) : null;
}

function getOnlyTexts(html) {
  const parser = new DOMParser();
  const content = parser.parseFromString(html, "text/html");
  const texts = content.querySelectorAll("p");
  console.log(texts);
  return texts ? Array.prototype.slice.call(texts).map((t) => t.innerHTML) : null;
}

function getCategories(data) {
  return data._embedded["wp:term"][0].map((d) => d.name);
}

function getTags(data) {
  return data._embedded["wp:term"][1].map((d) => d.name);
}

function getWriter(data) {
  return getTags(data)
    .filter((t) => t.startsWith("작가:"))
    .map((t) => t.split(":")[1]);
}

function getPublisher(data) {
  return getTags(data)
    .filter((t) => t.startsWith("출판사:"))
    .map((t) => t.split(":")[1]);
}

function removeHtmlTags(html) {
  return html.replace(/<[^>]+>/g, "");
}

function dateFormat(d) {
  const _d = new Date(d);
  const year = _d.getFullYear();
  const month = ("0" + (_d.getMonth() + 1)).slice(-2);
  const date = ("0" + _d.getDate()).slice(-2);
  const hour = ("0" + _d.getHours()).slice(-2);
  const minute = ("0" + _d.getMinutes()).slice(-2);
  return `${year}-${month}-${date} ${hour}:${minute}`;
}

export {
  getThumbnailImage,
  getEveryImages,
  getOnlyTexts,
  getCategories,
  removeHtmlTags,
  getTags,
  getWriter,
  getPublisher,
  dateFormat,
};
