import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../assets/logo.jpg";
import { ShareIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";

function Header() {
  const navigate = useNavigate();
  const location = useLocation();

  const pattern = /\/(pages|posts|books|photos)\/\d+/;
  const isArticle = pattern.test(location.pathname);

  const left = isArticle ? (
    <button onClick={() => navigate(-1)}>
      <ArrowLeftIcon className="h-6 w-6" />
    </button>
  ) : (
    <Link to="/" className="whitespace-nowrap">
      <img src={logo} alt="logo" className="mr-1 mb-1 inline h-6 w-auto" />
      <span className="mr-1 text-lg font-bold text-blue-800">(사)부천YWCA</span>
      <span className="text-xs font-bold text-gray-400">
        Young Women's Christian Association
      </span>
    </Link>
  );
  const center = isArticle ? "상세보기" : "";
  const right = isArticle ? (
    <ShareIcon onClick={share} className="h-6 w-6" />
  ) : (
    ""
  );

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl p-4">
        <div className="align-center flex justify-between">
          {left} {center} {right}
        </div>
      </div>
    </div>
  );
}

function share() {
  navigator.share({
    title: "부천YWCA",
    text: document.title,
    url: "",
  });
}

export default Header;
