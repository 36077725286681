import * as React from "react";
import { Routes, Route } from "react-router-dom";
// import { Routes, Route, Outlet, Link } from 'react-router-dom';

import Header from "./components/Header";
import Footer from "./components/Footer";
import Layout from "./components/Layout";
import Home from "./pages/Home";
import Article from "./pages/Article";
import Search from "./pages/Search";
import Contents from "./pages/Contents";
import NoMatch from "./pages/NoMatch";
import Photo from "./pages/Photo";
import Navigation from "./components/Navigation";

function App() {
  return (
    <Layout>
      <Header />
      <div
        style={{
          minHeight: "calc(100% - 354px)",
        }}
      >
        <Routes>
          <Route path="/">
            <Route index element={<Home />} />
            <Route
              path="posts/:id"
              element={<Article src={"main"} type={"posts"} />}
            />
            <Route
              path="pages/:id"
              element={<Article src={"main"} type={"pages"} />}
            />
            <Route
              path="books/:id"
              element={<Article src={"library"} type={"posts"} />}
            />
            <Route path="photos/:id" element={<Photo />} />
            <Route path="search" element={<Search />} />
            <Route path="contents" element={<Contents />} />
            <Route path="*" element={<NoMatch />} />
          </Route>
        </Routes>
      </div>
      <Footer />
      <Navigation />
    </Layout>
  );
}

export default App;
