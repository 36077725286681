import { useState, useEffect } from "react";
import Carousel from "../components/slides/Carousel";
import Icons from "../components/Icons";
import Books from "../components/slides/Books";
import Cards from "../components/slides/Cards";
import Thumbs from "../components/slides/Thumbs";
import Banners from "../components/slides/Banners";
import Vertical from "../components/slides/Vertical";
import * as stickers from "../assets/stickers";
import * as logos from "../assets/logos";
import * as banners from "../assets/banners";
import {
  getCategories,
  getPublisher,
  getThumbnailImage,
  getWriter,
  removeHtmlTags,
  dateFormat,
} from "../scripts/Utilities";

function Home() {
  // eslint-disable-next-line no-unused-vars
  const [title, setTitle] = useState("홈");
  useEffect(() => {
    document.title = `${title} | 부천YWCA`;
  }, [title]);

  const [featured, setFeatured] = useState([]);
  const [books, setBooks] = useState([]);
  const [news, setNews] = useState([]);
  const [notices, setNotices] = useState([]);
  const icons = [
    { icon: stickers.megaphone, text: "공지사항", link: "/contents#notice" },
    { icon: stickers.news, text: "소식", link: "/contents#news" },
    { icon: stickers.storytelling, text: "도서관", link: "/contents#library" },
    { icon: stickers.gallery, text: "사진", link: "/contents#gallery" },
    { icon: stickers.lightOn, text: "지음", link: "https://www.jium.club" },
    {
      icon: stickers.peacePigeon,
      text: "피스오브피스",
      link: "https://www.piecepeace.org",
    },
    { icon: stickers.broom, text: "가사돌봄", link: "/pages/273" },
    { icon: stickers.paintPalette, text: "미술관소개", link: "/pages/291" },
    { icon: stickers.solarPanel, text: "햇빛발전소", link: "/pages/1230" },
    { icon: stickers.co2, text: "탄소포인트", link: "/pages/1234" },
    { icon: stickers.map, text: "Y회원맵", link: "/pages/1210" },
    { icon: stickers.growingPlant, text: "평화와통일", link: "/pages/289" },
  ];
  const acts = [
    {
      link: "https://www.jium.club",
      image: logos.jium,
      categories: ["지음(知音)"],
      title: "생각을 나누는 책장",
      excerpt:
        "지음은 함께 살아가는 세상을 꿈꾸며 다양한 가치들을 공부하고, 나누는 활동을 하고 있습니다.",
    },
    {
      link: "https://www.piecepeace.org",
      image: logos.pieceOfPeace,
      categories: ["Piece of Peace"],
      title: "피스오브피스",
      excerpt:
        "피스오브피스는 함께 연대하며 사랑을 실천하고자 하는 청년 공동체입니다.",
    },
    {
      link: "/pages/273",
      image:
        "https://images.unsplash.com/photo-1546552696-7d5f4e89b0e8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
      categories: ["가사서비스"],
      title: "돌봄과 살림 협동조합",
      excerpt:
        "지역사회에 양질의 돌봄서비스를 제공하고자 살림돌보미, 산모돌보미를 각 가정에 보내드리는 서비스를 제공합니다.",
    },
    {
      link: "/pages/291",
      image:
        "https://images.unsplash.com/photo-1518998053901-5348d3961a04?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80",
      categories: ["미술관 소개"],
      title: "미술을 이야기하다",
      excerpt:
        "부천 시민의 인문 교양을 향상시킬 수 있는 주변의 미술관들을 소개합니다.",
    },
  ];
  const goals = [
    {
      link: "/pages/281",
      image: banners.hundred,
      title: "YWCA, 다시 100년",
      color: "#e0f2fe",
    },
    {
      link: "/pages/278",
      image: banners.ukraine,
      title: "우크라이나를 위한 기도",
      color: "#ecfccb",
    },
    {
      link: "/pages/289",
      image: banners.peacePigeon,
      title: "한반도 종전 선언",
      color: "#fef9c3",
    },
    {
      link: "/pages/285",
      image: banners.physics,
      title: "NO 핵발전, YES 재생에너지",
      color: "#fce7f3",
    },
  ];

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API_URL}/posts?sticky=true&_fields=id,title,content,excerpt,_links&_embed=wp:term`
    )
      .then((r) => r.json())
      .then((data) => {
        if (data && data.length > 0) {
          setFeatured(
            data.map((d) => ({
              link: `/posts/${d.id}`,
              image: getThumbnailImage(d.content.rendered),
              title: removeHtmlTags(d.title.rendered),
              content: removeHtmlTags(d.content.rendered),
              excerpt: removeHtmlTags(d.excerpt.rendered),
              categories: getCategories(d),
            }))
          );
        }
      });

    fetch(
      `${process.env.REACT_APP_LIB_URL}/posts?sticky=true&_fields=id,title,content,excerpt,_links&_embed=wp:term`
    )
      .then((r) => r.json())
      .then((data) => {
        if (data && data.length > 0) {
          setBooks(
            data.map((d) => ({
              link: `/books/${d.id}`,
              image: getThumbnailImage(d.content.rendered),
              title: removeHtmlTags(d.title.rendered),
              content: removeHtmlTags(d.content.rendered),
              excerpt: removeHtmlTags(d.excerpt.rendered),
              categories: getCategories(d),
              writer: getWriter(d),
              publisher: getPublisher(d),
            }))
          );
        }
      });

    fetch(
      `${process.env.REACT_APP_API_URL}/posts?categories=1&page=1&per_page=5&_fields=id,title,content,excerpt,date,_links&_embed=wp:term`
    )
      .then((r) => r.json())
      .then((data) => {
        if (data && data.length > 0) {
          setNews(
            data.map((d) => ({
              link: `/posts/${d.id}`,
              image: getThumbnailImage(d.content.rendered),
              title: removeHtmlTags(d.title.rendered),
              content: removeHtmlTags(d.content.rendered),
              excerpt: removeHtmlTags(d.excerpt.rendered),
              date: dateFormat(d.date),
              categories: getCategories(d),
            }))
          );
        }
      });

    fetch(
      `${process.env.REACT_APP_API_URL}/posts?categories=3&page=1&per_page=5&order=desc&_fields=id,title,_links&_embed=wp:term`
    )
      .then((r) => r.json())
      .then((data) => {
        if (data && data.length > 0) {
          setNotices(
            data.map((d) => ({
              link: `/posts/${d.id}`,
              title: removeHtmlTags(d.title.rendered),
              categories: getCategories(d),
            }))
          );
        }
      });

    return () => {
      featured.length = 0;
      books.length = 0;
      news.length = 0;
      notices.length = 0;
    };
  }, []);

  return (
    <div>
      <Carousel data={featured} />
      <Icons data={icons} />
      <div className="mt-8">
        <div className="p-6 text-xl font-bold">지금, 이 책.</div>
        <Books data={books} />
      </div>
      <div className="mt-8">
        <div className="p-6 text-xl font-bold">YWCA 소식</div>
        <Cards data={news} />
      </div>
      <div className="mt-8">
        <div className="p-6 text-xl font-bold">이런 활동도 하고 있어요</div>
        <Thumbs data={acts} />
      </div>
      <div className="mt-8">
        <div className="p-6 text-xl font-bold">부천 YWCA</div>
        <Banners data={goals} />
      </div>
      <div className="mt-8 border-t-2">
        <Vertical data={notices} />
      </div>
    </div>
  );
}

export default Home;
