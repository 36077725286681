import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { useNavigate } from "react-router-dom";

function Thumbs(props) {
  const navigate = useNavigate();
  return (
    <Swiper
      modules={[Pagination]}
      grabCursor={true}
      slidesPerView={2.5}
      initialSlide={0}
      spaceBetween={0}
      loop={true}
    >
      {props.data.map((d, i) => {
        return (
          <SwiperSlide key={i} className="mx-2">
            <button
              onClick={() =>
                d.link.startsWith("/")
                  ? navigate(d.link)
                  : window.open(d.link, "_blank")
              }
              className="text-left"
            >
              <div className="flex w-full flex-col items-center">
                <img
                  src={d.image}
                  alt="thumbs"
                  className="aspect-square h-auto w-full rounded-xl border-2 object-cover"
                />
                <div className="py-3">
                  {d.categories.map((c, ci) => (
                    <span
                      key={ci}
                      className="mr-1 rounded-full border border-gray-300 px-3 py-1 text-xs"
                    >
                      {c}
                    </span>
                  ))}
                  <div className="mt-3 font-semibold">{d.title}</div>
                  <div className="mt-1 text-sm text-gray-500">{d.excerpt}</div>
                </div>
              </div>
            </button>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}

export default Thumbs;
