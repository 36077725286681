import { useNavigate } from "react-router-dom";

function Icons(props) {
  const navigate = useNavigate();

  return (
    <div className="grid h-80 grid-cols-4 items-center justify-items-center p-3">
      {props.data.map((icon, index) => {
        return (
          <button
            key={index}
            onClick={() =>
              icon.link.startsWith("/")
                ? navigate(icon.link)
                : window.open(icon.link, "_blank")
            }
          >
            <div className="h-14 w-14 rounded-2xl bg-gray-100 p-3">
              <img src={icon.icon} alt="icon" />
            </div>
            <div className="mt-1 text-center text-xs">{icon.text}</div>
          </button>
        );
      })}
    </div>
  );
}

export default Icons;
